

body {
    /*background-color: #a08c6e;*/
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    text-decoration: none;
    cursor: pointer;
    /*color: #ffaa48;*/
    color: inherit;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
    font-Family: 'Nuffle';
    font-size: xxx-large;
}

h2 {
    font-Family: 'Nuffle';
    font-size: x-large;
}

h3 {
    font-Family: 'Nuffle';
    font-size: larger;
}

h4 {
    font-Family: 'Nuffle';
    font-size: large;
}

h5 {
    font-Family: 'Nuffle';
    font-size: medium;
}

h6 {
    font-Family: 'Nuffle';
    font-size: small;
}

@font-face {
    font-family: "Gutcruncher";
    src: local("Gutcruncher"),
    url("./fonts/Gutcruncher.otf") format("truetype");
    font-weight: auto;
}

@font-face {
    font-family: "OldLondon";
    src: local("OldLondon"),
    url("./fonts/OldLondon.ttf") format("truetype");
    font-weight: auto;
}

@font-face {
    font-family: "Kimberley";
    src: local("Kimberley"),
    url("./fonts/kimberley bl.otf") format("truetype");
    font-weight: auto;
}

@font-face {
    font-family: "Gutcruncher Italic";
    src: local("Gutcruncher Italic"),
    url("./fonts/Gutcruncher Italic.otf") format("truetype");
    font-weight: auto;
}

@font-face {
    font-family: "New Bartons";
    src: local("New Bartons"),
    url("./fonts/New Bartons.otf") format("truetype");
    font-weight: auto;
}

@font-face {
    font-family: "Nuffle";
    src: local("Nuffle"),
    url("./fonts/Nuffle.otf") format("truetype");
    font-weight: auto;
}

@font-face {
    font-family: "Nuffle Dice";
    src: local("Nuffle Dice"),
    url("./fonts/Nuffle Dice.otf") format("truetype");
    font-weight: auto;
}

@font-face {
    font-family: "Nuffle Italic";
    src: local("Nuffle Italic"),
    url("./fonts/Nuffle Italic.otf") format("truetype");
    font-weight: auto;
}

@font-face {
    font-family: "BBDice";
    src: local("DpolyBlockDice-Weo9"),
    url("./fonts/DpolyBlockDice-Weo9.ttf") format("truetype");
    font-weight: auto;
}

@keyframes diceSelectedUp {
    from {
        font-size: 1rem;
        opacity: 1;
    }
    to{
        font-size: 5rem;
        opacity: 0;
    }

}

@media print {
    * {
        -webkit-print-color-adjust: exact !important; /*Chrome, Safari */
        print-color-adjust: exact !important;  /*Firefox*/
    }

    .no-print, .no-print *
    {
        display: none !important;
    }
}


/* CSS ICON ALERT GENERAL */
.screenAlert-icon{border-radius:50%;border:3px solid gray;box-sizing:content-box;height:24px;width:24px;margin:5px auto;padding:0;position:relative}
.screenAlert-icon + .screenAlert-icon {margin-top:50px}


.screenAlert-icon.screenAlert-warning{border-color: #ffa200
}
.screenAlert-icon.screenAlert-warning:before{-webkit-animation:pulseWarning 2s linear infinite;animation:pulseWarning 2s linear infinite;background-color:transparent!important;border-radius:50%;content:"";display:inline-block;height:100%;opacity:0;position:absolute;width:100%}
.screenAlert-icon.screenAlert-warning:after{background-color:transparent!important;border-radius:50%;content:'';display:block;height:100%;position:absolute;width:100%;z-index:1}
.screenAlert-icon.screenAlert-warning .screenAlert-body{background-color: #ffa200;border-radius:2px;height:13px;left:50%;margin-left:-2px;position:absolute;top:2px;width:5px;z-index:2}
.screenAlert-icon.screenAlert-warning .screenAlert-dot{background-color: #ffa200;border-radius:50%;bottom:1px;height:7px;left:50%;margin-left:-3px;position:absolute;width:7px;z-index:2}
.scaleWarning{-webkit-animation:scaleWarning .75s infinite alternate;animation:scaleWarning .75s infinite alternate}
.pulseWarningIns{-webkit-animation:pulseWarningIns .75s infinite alternate;animation:pulseWarningIns .75s infinite alternate}
/* ANIMATE WARNING */
@-webkit-keyframes scaleWarning{0%{-webkit-transform:scale(1);transform:scale(1)}30%{-webkit-transform:scale(1.05);transform:scale(1.05)}100%{-webkit-transform:scale(1);transform:scale(1)}}
@keyframes scaleWarning{0%{-webkit-transform:scale(1);transform:scale(1)}30%{-webkit-transform:scale(1.05);transform:scale(1.05)}100%{-webkit-transform:scale(1);transform:scale(1)}}
@-webkit-keyframes pulseWarning{0%{background-color: #ffb333;-webkit-transform:scale(1);transform:scale(1);opacity:.5} 30%{background-color: #ffb333;-webkit-transform:scale(1);transform:scale(1);opacity:.5} 100%{background-color: #ffa200;-webkit-transform:scale(2);transform:scale(2);opacity:0}}
@keyframes pulseWarning{0%{background-color: #ffb333;-webkit-transform:scale(1);transform:scale(1);opacity:.5} 30%{background-color: #ffb333;-webkit-transform:scale(1);transform:scale(1);opacity:.5} 100%{background-color: #ffa200;-webkit-transform:scale(2);transform:scale(2);opacity:0}}
@-webkit-keyframes pulseWarningIns{0%{background-color: #ffb333
} 100%{background-color: #ffa200
                                                               }}
@keyframes pulseWarningIns{0%{background-color: #ffb333
} 100%{background-color: #ffa200
                                                       }}
