.bbsa-newspaper-root {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-width: 1024px;
}

.bbsa-newspaper-header {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.bbsa-newspaper-content {
    background-repeat: repeat;
    background-position: center;
    background-size: contain;
}

.bbsa-newspaper-title {
    font-size: 5em;
    margin-top: 0px;
    margin-bottom: 0px;
}

.bbsa-newspaper-date {
    margin-top: 0px;
    margin-bottom: 0px;
}

.bbsa-newspaper-blason {
    width: auto;
    height: 100px;
}

.bbsa-newspaper-title-divider {
    flex-shrink: 0;
    border-width: 0;
    border-style: solid;
    border-color: black !important;
    border-bottom-width: thin;
}
